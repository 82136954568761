import { ROLE } from "@constants/role";
import { reservationStatus } from "@constants/reservationStatus";

export interface IActionProps {
  label: string;
  dataObject: any;
  onDelete?: Function;
}

export interface ITableToolbarProps {
  onFilterChange: Function;
  canCreate: boolean;
}

export interface IDateToolbarProps {
  filteredDate: string;
  setFilteredDate: any;
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case reservationStatus.RESERVED:
      return "#2980B9";
    case reservationStatus.CHECKIN:
      return "green";
    case reservationStatus.CHECKOUT:
      return "#f50";
    case reservationStatus.COMPLETED:
      return "#808080";

    case reservationStatus.ARCHIVED:
      return "red";
    default:
      return "#2980B9";
  }
};

export const getStatusBackgroundColor = (
  companyName: string,
  status: string
) => {
  if (companyName === "Expedia" && status !== reservationStatus.COMPLETED) {
    return "#FEBF4F";
  }
  if (companyName === "Booking" && status !== reservationStatus.COMPLETED) {
    return "#003580";
  }

  switch (status) {
    case reservationStatus.RESERVED:
      return "#AED6F1";
    case reservationStatus.CHECKIN:
      return "#A2D9CE";
    case reservationStatus.CHECKOUT:
      return "#FFA07A";
    case reservationStatus.COMPLETED:
      return "#808080";
    default:
      return "#AED6F1";
  }
};

export const getRoleColor = (status: string) => {
  switch (status) {
    case ROLE.ADMIN:
      return "gold";
    case ROLE.GUEST:
      return "blue";
    case ROLE.RECEPCIONIST:
      return "green";
    default:
      return "blue";
  }
};

export const getReservationStatusColor = (status: string | null = null) => {
  switch (status) {
    case reservationStatus.CHECKIN:
      return "#52c41a";
    case reservationStatus.CHECKOUT:
      return "#eb2f96";
    default:
      return "";
  }
};
