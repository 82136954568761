import React, { useState } from "react";
import { Avatar, Menu, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { doLogoutAsync } from "@store/slices/auth";
import { getModalHeader } from "@components/Common/Render";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import {
  BankOutlined,
  ShopOutlined,
  TeamOutlined,
  RestOutlined,
  ToolOutlined,
  LogoutOutlined,
  HistoryOutlined,
  SettingOutlined,
  FileTextOutlined,
  CalendarOutlined,
  SolutionOutlined,
  BarChartOutlined,
  ScheduleOutlined,
  ContainerOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

const MenuComponent: React.FC<any> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const name = user.displayName;
  const initials = name?.match(/\b(\w)/g).join("") || "";

  const [logoutOpen, setLogoutOpen] = useState<any>(false);
  const { isAdmin } = useAppSelector((state) => state.auth);

  const profile = {
    key: "profile",
    label: name,
    icon: (
      <Avatar className="avatar-class" shape="square" size={24}>
        {initials}
      </Avatar>
    ),
  };

  const getReportChildren = () => {
    let items = [
      getItem(
        "Cierre de Caja",
        "cashRegisterClosingReport",
        <SolutionOutlined />
      ),
    ];
    if (isAdmin) {
      items = items.concat(
        getItem(
          "Ventas por Método de pago",
          "salesReportByPaymentMethod",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Habitación",
          "salesReportByRooms",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Tipos de Habitación",
          "salesReportByRoomTypes",
          <BarChartOutlined />
        ),
        getItem(
          "Ventas por Cantidad de Tipos de Habitación",
          "reportByRoomTypeQuantity",
          <BarChartOutlined />
        )
      );
    }
    return items;
  };

  const maintenance = getItem("Mantenimiento", "456", <SettingOutlined />, [
    getItem("Usuarios", "users", <TeamOutlined />),
    getItem("Empresas", "companies", <BankOutlined />),
    getItem("Productos", "products", <RestOutlined />),
    getItem("Servicios", "services", <ToolOutlined />),
    getItem("Habitaciones", "rooms", <ShopOutlined />),
    getItem("Cotizaciones", "quotes", <ScheduleOutlined />),
    getItem("Facturación", "invoiceConfiguration", <ReconciliationOutlined />),
  ]);

  const history = getItem("Historial", "789", <HistoryOutlined />, [
    getItem("Reservaciones", "reservationsHistory", <ContainerOutlined />),
    getItem("Facturas", "invoicesHistory", <FileTextOutlined />),
  ]);

  const items: any = [
    profile,
    getItem("Agenda", "timeline", <CalendarOutlined />),
    getItem("Reservaciones", "reservations", <ContainerOutlined />),
  ];

  const reports = getItem("Reportes", "123", <BarChartOutlined />, [
    ...getReportChildren(),
  ]);

  items.push(maintenance);
  items.push(history);
  items.push(reports);
  items.push(getItem("Logout", "logout", <LogoutOutlined />));

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: any[]
  ): any {
    return {
      key,
      icon,
      children,
      label,
    } as any;
  }

  const onMenuItemClick = (menu: any) => {
    if (menu.key === "profile") return;
    if (menu.key === "logout") {
      setLogoutOpen(true);
      return;
    }
    navigate(menu.key);
  };

  const onLogout = async () => {
    await dispatch(doLogoutAsync());
    navigate("/");
  };

  return (
    <div>
      <Menu
        mode="inline"
        items={items}
        onClick={onMenuItemClick}
        defaultSelectedKeys={["1"]}
      />
      <Modal
        centered
        width={400}
        okText="Logout"
        open={logoutOpen}
        onOk={() => onLogout()}
        onCancel={() => setLogoutOpen(false)}
        title={getModalHeader("Desea hacer logout?")}
      ></Modal>
    </div>
  );
};

export default MenuComponent;
