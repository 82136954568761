import React, { useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import { useAppDispatch } from "@store/hooks";
import { useNavigate } from "react-router-dom";
import { getModalHeader } from "@components/Common/Render";
import { reservationStatus } from "@constants/reservationStatus";
import { updateReservationStatusAsync } from "@store/slices/reservation";
import { EditFilled, DeleteOutlined, DollarOutlined } from "@ant-design/icons";

import CheckinComponent from "../CheckinComponent";
import IReservation from "@interfaces/IReservation";

export interface IActionProps {
  onEdit?: Function;
  onPayment?: Function;
  reservation: IReservation;
}

const ActionComponent: React.FC<IActionProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const onEdit = async (reservation: IReservation | null) => {
    if (!reservation) return;
    props.onEdit ? props.onEdit() : navigate(`form/${reservation.id}`);
  };

  const onPayment = async (reservation: IReservation | null) => {
    if (!reservation) return;
    props.onPayment
      ? props.onPayment()
      : navigate(`reservationInvoice/${reservation.id}`);
  };

  const onDelete = async (reservation: IReservation | null) => {
    if (!reservation) return;
    await dispatch(
      updateReservationStatusAsync({
        id: reservation.id,
        status: reservationStatus.ARCHIVED,
      })
    );
  };

  return (
    <>
      <Tooltip title="Editar">
        <Button
          ghost
          className="ghost-button"
          icon={<EditFilled onClick={() => onEdit(props.reservation)} />}
        />
      </Tooltip>
      <CheckinComponent reservation={props.reservation} />
      <Tooltip title="Pagar">
        <Button
          ghost
          className="ghost-button"
          icon={<DollarOutlined onClick={() => onPayment(props.reservation)} />}
        />
      </Tooltip>
      <Tooltip title="Borrar">
        <Button
          ghost
          className="ghost-button"
          icon={<DeleteOutlined onClick={() => setConfirmOpen(true)} />}
        />
      </Tooltip>

      <Modal
        centered
        open={confirmOpen}
        okText="Confirmar"
        cancelText="Cancelar"
        onCancel={() => setConfirmOpen(false)}
        onOk={async () => await onDelete(props.reservation)}
        title={getModalHeader("Esta seguro de borrar la reservación?")}
      ></Modal>
    </>
  );
};

export default ActionComponent;
