import React, { useEffect, useState } from "react";
import { Layout, Modal, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { toastHandler } from "@utils/toastHandler";
import { DndContext, pointerWithin } from "@dnd-kit/core";
import { getModalHeader } from "@components/Common/Render";
import { getTodayString, getDateString } from "@utils/date";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { getDataSource } from "@components/Timeline/Components";
import { reservationStatus } from "@constants/reservationStatus";
import { fetchReservationAsync } from "@store/slices/reservation";

import {
  fetchTimelineAsync,
  updateTimelineReservationAsync,
} from "@store/slices/timeline";

import {
  getColumns,
  getUpdatedTimelineReservation,
} from "@components/Timeline/helpers";

import scrollIntoView from "scroll-into-view";
import TimelineModal from "@components/Timeline/Modal";

const Timeline: React.FC = () => {
  const dispatch = useAppDispatch();

  const [dragObject, setDragObject] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(() => getTodayString());

  const { timeline, loading } = useAppSelector((state) => state.timeline);
  const { selectedReservation, loading: loadingReservation } = useAppSelector(
    (state) => state.reservation
  );

  useEffect(() => {
    scrollIntoView(document.querySelector(".row-class"));
  }, [timeline]);

  useEffect(() => {
    if (!selectedReservation && !loadingReservation) {
      setEditModalOpen(false);
    }
  }, [selectedReservation, loadingReservation]);

  useEffect(() => {
    if (!timeline) {
      dispatch(fetchTimelineAsync(selectedDate));
      setEditModalOpen(false);
    }
  }, [dispatch, timeline, selectedDate]);

  useEffect(() => {
    dispatch(fetchTimelineAsync(selectedDate));
    setEditModalOpen(false);
  }, [dispatch, selectedDate]);

  const onDateChange = (selectedDate: any) =>
    setSelectedDate(getDateString(selectedDate));

  const columns: any = getColumns(timeline, selectedDate, onDateChange);
  const dataSource = getDataSource(timeline, selectedDate);

  const handleDragEnd = async (event: any) => {
    const active = event?.active?.data?.current;
    const over = event?.over?.data?.current;
    const isDragged = event?.delta?.x !== 0 || event?.delta?.y !== 0;
    if (over && active?.reservation?.status === reservationStatus.COMPLETED) {
      toastHandler.info("Reservaciones completadas no se pueden modificar.");
      return;
    }
    if (isDragged && over) {
      setDragObject({ active, over });
    }

    if (!isDragged) {
      setEditModalOpen(true);
      await dispatch(fetchReservationAsync(active?.reservation.reservationId));
    }
  };

  const handleUpdateReservation = async ({ active, over }: any) => {
    const updatedTimelineReservation = getUpdatedTimelineReservation(
      active,
      over
    );
    await dispatch(updateTimelineReservationAsync(updatedTimelineReservation));
    setDragObject(null);
  };

  return (
    <Layout>
      <Content>
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          <Table
            columns={columns}
            loading={loading}
            pagination={false}
            rowKey={(x) => x.id}
            dataSource={dataSource}
            rowClassName="row-class"
            scroll={{ x: "max-content" }}
          />
        </DndContext>

        {editModalOpen && <TimelineModal />}

        <Modal
          centered
          open={dragObject}
          okText="Confirmar"
          cancelText="Cancelar"
          onCancel={() => setDragObject(null)}
          onOk={() => handleUpdateReservation(dragObject)}
          title={getModalHeader(
            `Mover reservación de ${dragObject?.active?.reservation?.userName}?`
          )}
        ></Modal>
      </Content>
    </Layout>
  );
};

export default Timeline;
